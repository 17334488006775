// src/App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import Header from "./components/Header";
import ProtectedRoute from "./components/ProtectedRoute";

import SystemHealth from "./pages/SystemHealth";
import AssetUtilization from "./pages/AssetUtilization";
import Safety from "./pages/Safety";
// import History from "./pages/History";
import Comparison from "./pages/Comparison";
import Login from "./pages/Login";

import "./App.css";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <div className="App">
          {/* Left Sidebar for Navigation */}
          <div className="sidebar">
            <h2>LoopX Dashboard</h2>
            <nav>
              <ul className="nav-list">
                {/* <li>
                  <NavLink to="/SystemHealth" className="nav-link">
                    System Health
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to="/AssetUtilization" className="nav-link">
                    Asset Utilization
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/safety" className="nav-link">
                    Safety
                  </NavLink>
                </li> */}
                {/* Uncomment if you want to include History */}
                {/* <li>
                  <NavLink to="/history" className="nav-link">
                    History
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to="/comparison" className="nav-link">
                    Vehicle Comparison
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>

          {/* Right Content Area */}
          <div className="content">
            <Routes>
              <Route path="/login" element={<Login />} />

              {/* Protected Routes */}
              <Route
                path="/SystemHealth"
                element={
                  <ProtectedRoute>
                    <SystemHealth />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/AssetUtilization"
                element={
                  <ProtectedRoute>
                    <AssetUtilization />
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path="/safety"
                element={
                  <ProtectedRoute>
                    <Safety />
                  </ProtectedRoute>
                }
              /> */}
              {/* <Route
                path="/history"
                element={
                  <ProtectedRoute>
                    <History />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="/comparison"
                element={
                  <ProtectedRoute>
                    <Comparison />
                  </ProtectedRoute>
                }
              />

              {/* Redirect any unknown routes to login */}
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
