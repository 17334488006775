// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect } from "react";

// Create the AuthContext
export const AuthContext = createContext();

// AuthProvider component to wrap around the app
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Check localStorage for existing user session on mount
  useEffect(() => {
    const storedUser = localStorage.getItem("authenticatedUser");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  // Login function
  const login = (email, password) => {
    // Hardcoded credentials
    const validEmail = "ryan.payne@cementation.com";
    const validPassword = "QwAsZx12#$";

    if (email === validEmail && password === validPassword) {
      const userData = { email, name: "Testing User" }; // You can expand user data as needed
      setUser(userData);
      localStorage.setItem("authenticatedUser", JSON.stringify(userData));
      return { success: true };
    } else {
      return { success: false, message: "Invalid email or password." };
    }
  };

  // Logout function
  const logout = () => {
    setUser(null);
    localStorage.removeItem("authenticatedUser");
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
