// src/pages/Comparison.js

import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chart.js/auto";
import "./Comparison.css";

// Material-UI Components
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Button,
} from "@mui/material";

// Register Chart.js plugins
Chart.register(ChartDataLabels);

function Comparison() {
  // -----------------------------
  // Helper Functions
  // -----------------------------

  // Helper function to format duration from minutes to "X hours Y minutes"
  function formatDuration(minutes) {
    if (minutes < 60) {
      return `${Math.round(minutes)} minute${minutes !== 1 ? "s" : ""}`;
    } else {
      const hrs = Math.floor(minutes / 60);
      const mins = Math.round(minutes % 60);
      let result = `${hrs} hour${hrs !== 1 ? "s" : ""}`;
      if (mins > 0) {
        result += ` ${mins} minute${mins !== 1 ? "s" : ""}`;
      }
      return result;
    }
  }

  // Helper function to format percentage
  function formatPercentage(percentage) {
    return `${percentage.toFixed(1)}%`;
  }

  // Function to format shift names
  function formatShifts(shifts) {
    if (shifts.length === 0) return "No Shifts Selected";
    if (shifts.length === 1) return `${shifts[0]} Shift`;
    return `${shifts.join(", ")} Shifts`;
  }

  // -----------------------------
  // State Variables
  // -----------------------------

  // Raw data fetched from backend
  const [timeCalculationData, setTimeCalculationData] = useState([]);

  // Data after splitting logs by shifts and days
  const [shiftedData, setShiftedData] = useState([]);

  // Selected Filters
  const [selectedVehicle1, setSelectedVehicle1] = useState(""); // Initially no selection
  const [selectedVehicle2, setSelectedVehicle2] = useState(""); // Initially no selection
  const [selectedMonth, setSelectedMonth] = useState(11); // November by default
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);

  // Shift Filters for both vehicles
  const [selectedShifts1, setSelectedShifts1] = useState(["Morning", "Night"]);
  const [selectedShifts2, setSelectedShifts2] = useState(["Morning", "Night"]);

  // -----------------------------
  // Configuration Data
  // -----------------------------

  // Define unique host options with corresponding host IDs
  const hostOptions = [
    { label: "cem001", value: ["cem001"] },
    { label: "TOYOTA", value: ["cem003", "cem009"] }, // Combined Hosts
    { label: "R1700 Scoop (Serial# CS46629)", value: ["cem004"] },
    { label: "cem005", value: ["cem005"] },
    { label: "AD30 Truck (serial # CU9810)", value: ["cem006"] },
    { label: "AD45 Truck (Serial #CS44476)", value: ["cem008"] },
  ];

  // Mapping of individual host IDs to human-readable names
  const hostNameMapping = {
    cem001: "Device 1 (Serial# XX12345)",
    cem002: "Device 2 (Serial# XX67890)",
    cem003: "TOYOTA (serial # CR46744)",
    cem004: "R1700 Scoop (Serial# CS46629)",
    cem005: "Device 5 (Serial# XX54321)",
    cem006: "AD30 Truck (serial # CU9810)",
    cem008: "AD45 Truck (Serial #CS44476)",
    cem009: "TOYOTA (serial # CR46745)", // Assuming cem009 exists
  };

  // List of vehicle IDs
  const vehicles = [
    "cem001",
    "cem002",
    "cem003",
    "cem004",
    "cem005",
    "cem006",
    "cem008",
    "cem009", // Added if exists
  ];

  // List of months for dropdown selection
  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  // List of weeks and days for dropdowns
  const weeksOptions = ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"];
  const daysOptions = Array.from({ length: 31 }, (_, i) => `Day ${i + 1}`);

  // List of shifts
  const shiftsOptions = ["Morning", "Night"];

  // -----------------------------
  // Data Fetching and Processing
  // -----------------------------

  // Fetch data from backend on component mount
  useEffect(() => {
    fetch("/api/time-calculation")
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched Time Calculation Data:", data); // Debugging log
        if (data.length > 0) {
          setTimeCalculationData(data);
          const processedData = splitLogsByShifts(data);
          setShiftedData(processedData);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // Define shift times
  function getShiftTimes(date) {
    const morningStart = new Date(date);
    morningStart.setHours(6, 0, 0, 0); // 6:00 AM
    const morningEnd = new Date(date);
    morningEnd.setHours(18, 0, 0, 0); // 6:00 PM

    const nightStart = new Date(date);
    nightStart.setHours(18, 0, 0, 0); // 6:00 PM
    const nightEnd = new Date(date);
    nightEnd.setDate(nightEnd.getDate() + 1);
    nightEnd.setHours(6, 0, 0, 0); // 6:00 AM next day

    return {
      Morning: { start: morningStart, end: morningEnd },
      Night: { start: nightStart, end: nightEnd },
    };
  }

  // Function to split a single log into multiple logs based on shifts and days
  function splitLogByShifts(log) {
    const splitLogs = [];
    const masterStart = new Date(log["Master Start"]);
    const masterEnd = new Date(log["Master End"]);

    // Initialize current time
    let currentTime = new Date(masterStart);

    while (currentTime < masterEnd) {
      const shiftTimes = getShiftTimes(currentTime);

      // Determine which shift we're in
      let currentShift = null;
      if (
        currentTime >= shiftTimes.Morning.start &&
        currentTime < shiftTimes.Morning.end
      ) {
        currentShift = "Morning";
      } else {
        currentShift = "Night";
      }

      const shiftStart = shiftTimes[currentShift].start;
      const shiftEnd = shiftTimes[currentShift].end;

      // Calculate the end time for this segment
      const segmentEnd = shiftEnd > masterEnd ? masterEnd : shiftEnd;

      // Calculate duration in minutes
      const duration =
        (segmentEnd - currentTime) / (1000 * 60); // Convert ms to minutes

      // Assign durations proportionally based on the original shift durations
      const morningShiftDuration = log["Morning Shift (mins)"];
      const nightShiftDuration = log["Night Shift (mins)"];
      const totalShiftDuration = morningShiftDuration + nightShiftDuration;

      // Avoid division by zero
      if (totalShiftDuration === 0) {
        splitLogs.push({
          Host: log.Host,
          Date: shiftStart.toLocaleDateString(),
          Shift: currentShift,
          ForwardDuration: 0,
          ReverseDuration: 0,
          IdleDuration: 0,
        });
      } else {
        splitLogs.push({
          Host: log.Host,
          Date: shiftStart.toLocaleDateString(),
          Shift: currentShift,
          ForwardDuration:
            (log["Forward Duration (mins)"] * duration) / totalShiftDuration,
          ReverseDuration:
            (log["Reverse Duration (mins)"] * duration) / totalShiftDuration,
          IdleDuration:
            (log["Idle Duration (mins)"] * duration) / totalShiftDuration,
        });
      }

      // Move to the next segment
      currentTime = new Date(segmentEnd);
    }

    return splitLogs;
  }

  // Function to split all logs by shifts
  function splitLogsByShifts(data) {
    const allSplitLogs = [];
    data.forEach((log) => {
      const splits = splitLogByShifts(log);
      allSplitLogs.push(...splits);
    });
    return allSplitLogs;
  }

  // Function to filter data based on selected vehicle, shifts, month, weeks, and days
  function filterData(vehicle, shifts) {
    // Find the host option corresponding to the selected vehicle
    const hostOption = hostOptions.find((host) => host.label === vehicle);
    if (!hostOption) return [];

    const hostIDs = hostOption.value; // Array of host IDs

    let filtered = shiftedData.filter(
      (item) =>
        hostIDs.includes(item.Host) &&
        new Date(item.Date).getMonth() + 1 === selectedMonth &&
        (shifts.length === 0 || shifts.includes(item.Shift))
    );

    // Filter by weeks
    if (selectedWeeks.length > 0) {
      filtered = filtered.filter((item) => {
        const day = new Date(item.Date).getDate();
        const weekNumber = Math.ceil(day / 7);
        return selectedWeeks.includes(`Week ${weekNumber}`);
      });
    }

    // Filter by days
    if (selectedDays.length > 0) {
      filtered = filtered.filter((item) => {
        const day = new Date(item.Date).getDate();
        return selectedDays.includes(`Day ${day}`);
      });
    }

    return filtered;
  }

  // Function to aggregate durations by day
  function aggregateDataByDay(data) {
    const aggregatedData = {};
    data.forEach((item) => {
      const day = new Date(item.Date).getDate();
      if (!aggregatedData[day]) {
        aggregatedData[day] = { Forward: 0, Reverse: 0, Idle: 0 };
      }
      aggregatedData[day].Forward += item.ForwardDuration;
      aggregatedData[day].Reverse += item.ReverseDuration;
      aggregatedData[day].Idle += item.IdleDuration;
    });
    const labels = Object.keys(aggregatedData)
      .map((day) => parseInt(day))
      .sort((a, b) => a - b);
    const forwardDurations = labels.map((day) => aggregatedData[day].Forward);
    const reverseDurations = labels.map((day) => aggregatedData[day].Reverse);
    const idleDurations = labels.map((day) => aggregatedData[day].Idle);
    return { labels, forwardDurations, reverseDurations, idleDurations };
  }

  // Function to calculate KPIs and format durations and percentages
  function calculateKPIs(forwardDurations, reverseDurations, idleDurations) {
    const totalForward = forwardDurations.reduce((a, b) => a + b, 0);
    const totalReverse = reverseDurations.reduce((a, b) => a + b, 0);
    const totalIdle = idleDurations.reduce((a, b) => a + b, 0);

    const totalDuration = totalForward + totalReverse + totalIdle;

    const percentageForward =
      totalDuration > 0 ? (totalForward / totalDuration) * 100 : 0;
    const percentageReverse =
      totalDuration > 0 ? (totalReverse / totalDuration) * 100 : 0;
    const percentageIdle =
      totalDuration > 0 ? (totalIdle / totalDuration) * 100 : 0;

    const averageForward =
      forwardDurations.length > 0
        ? totalForward / forwardDurations.length
        : 0;
    const averageReverse =
      reverseDurations.length > 0
        ? totalReverse / reverseDurations.length
        : 0;
    const averageIdle =
      idleDurations.length > 0 ? totalIdle / idleDurations.length : 0;

    const maxForward =
      forwardDurations.length > 0 ? Math.max(...forwardDurations) : 0;
    const maxReverse =
      reverseDurations.length > 0 ? Math.max(...reverseDurations) : 0;
    const maxIdle =
      idleDurations.length > 0 ? Math.max(...idleDurations) : 0;

    const minForward =
      forwardDurations.length > 0 ? Math.min(...forwardDurations) : 0;
    const minReverse =
      reverseDurations.length > 0 ? Math.min(...reverseDurations) : 0;
    const minIdle =
      idleDurations.length > 0 ? Math.min(...idleDurations) : 0;

    return {
      totalForward: formatDuration(totalForward),
      percentageForward: formatPercentage(percentageForward),
      totalReverse: formatDuration(totalReverse),
      percentageReverse: formatPercentage(percentageReverse),
      totalIdle: formatDuration(totalIdle),
      percentageIdle: formatPercentage(percentageIdle),
      averageForward: formatDuration(averageForward),
      averageReverse: formatDuration(averageReverse),
      averageIdle: formatDuration(averageIdle),
      maxForward: formatDuration(maxForward),
      maxReverse: formatDuration(maxReverse),
      maxIdle: formatDuration(maxIdle),
      minForward: formatDuration(minForward),
      minReverse: formatDuration(minReverse),
      minIdle: formatDuration(minIdle),
    };
  }

  // Aggregate data for selected vehicles and month with shifts
  const dataForVehicle1 = selectedVehicle1
    ? aggregateDataByDay(filterData(selectedVehicle1, selectedShifts1))
    : null;
  const dataForVehicle2 = selectedVehicle2
    ? aggregateDataByDay(filterData(selectedVehicle2, selectedShifts2))
    : null;

  // Calculate KPIs for selected vehicles
  const kpiVehicle1 = dataForVehicle1
    ? calculateKPIs(
        dataForVehicle1.forwardDurations,
        dataForVehicle1.reverseDurations,
        dataForVehicle1.idleDurations
      )
    : {};
  const kpiVehicle2 = dataForVehicle2
    ? calculateKPIs(
        dataForVehicle2.forwardDurations,
        dataForVehicle2.reverseDurations,
        dataForVehicle2.idleDurations
      )
    : {};

  // -----------------------------
  // Chart Configuration
  // -----------------------------

  // Define chart colors
  const durationColors = {
    Forward: "#4CAF50", // Green
    Reverse: "#9E9E9E", // Grey
    Idle: "#FF9800", // Orange
  };

  // Chart options with stacking enabled
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: { display: true, text: "Day of the Month" },
        stacked: true,
        ticks: { autoSkip: true, maxTicksLimit: 15 },
      },
      y: {
        beginAtZero: true,
        title: { display: true, text: "Duration (Minutes)" },
        stacked: true,
      },
    },
    plugins: {
      datalabels: {
        display: false, // Disables data labels on bars
      },
      legend: {
        position: "top",
        labels: { boxWidth: 15, boxHeight: 15 },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: ${formatDuration(
              context.parsed.y
            )}`;
          },
        },
      },
    },
  };

  // -----------------------------
  // Handlers for Filters
  // -----------------------------

  // Handler for selecting/deselecting weeks
  const handleWeeksChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedWeeks(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // Handler for selecting/deselecting days with "Select All" functionality
  const handleDaysChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes("Select All")) {
      if (selectedDays.length === daysOptions.length) {
        setSelectedDays([]);
      } else {
        setSelectedDays(daysOptions);
      }
    } else {
      setSelectedDays(value);
    }
  };

  // Check if all days are selected
  const allDaysSelected = selectedDays.length === daysOptions.length;

  // Handlers for Shift selections
  const handleShifts1Change = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedShifts1(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleShifts2Change = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedShifts2(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // Clear all filters
  const clearAllFilters = () => {
    setSelectedVehicle1("");
    setSelectedVehicle2("");
    setSelectedMonth(11); // Reset to November or your desired default
    setSelectedWeeks([]);
    setSelectedDays([]);
    setSelectedShifts1(["Morning", "Night"]);
    setSelectedShifts2(["Morning", "Night"]);
  };

  // -----------------------------
  // Rendering the Component
  // -----------------------------

  return (
    <div className="comparison-container">
      <h2 className="comparison-title">Asset Utilization Comparison</h2>
      <div className="dropdown-container">
        {/* Comparison Sides */}
        <div className="comparison-sides">
          {/* Vehicle 1 and Shift 1 Group */}
          <div className="comparison-side">
            {/* Vehicle 1 Dropdown */}
            <FormControl variant="outlined" className="dropdown-group">
              <InputLabel id="vehicle1-select-label">Select Vehicle 1</InputLabel>
              <Select
                labelId="vehicle1-select-label"
                id="vehicle1-select"
                value={selectedVehicle1}
                onChange={(e) => setSelectedVehicle1(e.target.value)}
                label="Select Vehicle 1"
              >
                <MenuItem value="">
                  <em>-- Select Vehicle 1 --</em>
                </MenuItem>
                {hostOptions.map((vehicle) => (
                  <MenuItem key={vehicle.label} value={vehicle.label}>
                    {hostNameMapping[vehicle.value[0]] || vehicle.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Shift Dropdown for Vehicle 1 */}
            <FormControl variant="outlined" className="dropdown-group">
              <InputLabel id="shift1-select-label">Select Shift 1</InputLabel>
              <Select
                labelId="shift1-select-label"
                id="shift1-select"
                multiple
                value={selectedShifts1}
                onChange={handleShifts1Change}
                input={<OutlinedInput label="Select Shift 1" />}
                renderValue={(selected) => {
                  if (selected.length === 0) return "No Shifts Selected";
                  if (selected.length === shiftsOptions.length) return "All Shifts";
                  return selected.join(", ");
                }}
              >
                {shiftsOptions.map((shift) => (
                  <MenuItem key={shift} value={shift}>
                    <Checkbox checked={selectedShifts1.indexOf(shift) > -1} />
                    <ListItemText primary={shift} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* Vehicle 2 and Shift 2 Group */}
          <div className="comparison-side">
            {/* Vehicle 2 Dropdown */}
            <FormControl variant="outlined" className="dropdown-group">
              <InputLabel id="vehicle2-select-label">Select Vehicle 2</InputLabel>
              <Select
                labelId="vehicle2-select-label"
                id="vehicle2-select"
                value={selectedVehicle2}
                onChange={(e) => setSelectedVehicle2(e.target.value)}
                label="Select Vehicle 2"
              >
                <MenuItem value="">
                  <em>-- Select Vehicle 2 --</em>
                </MenuItem>
                {hostOptions.map((vehicle) => (
                  <MenuItem key={vehicle.label} value={vehicle.label}>
                    {hostNameMapping[vehicle.value[0]] || vehicle.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Shift Dropdown for Vehicle 2 */}
            <FormControl variant="outlined" className="dropdown-group">
              <InputLabel id="shift2-select-label">Select Shift 2</InputLabel>
              <Select
                labelId="shift2-select-label"
                id="shift2-select"
                multiple
                value={selectedShifts2}
                onChange={handleShifts2Change}
                input={<OutlinedInput label="Select Shift 2" />}
                renderValue={(selected) => {
                  if (selected.length === 0) return "No Shifts Selected";
                  if (selected.length === shiftsOptions.length) return "All Shifts";
                  return selected.join(", ");
                }}
              >
                {shiftsOptions.map((shift) => (
                  <MenuItem key={shift} value={shift}>
                    <Checkbox checked={selectedShifts2.indexOf(shift) > -1} />
                    <ListItemText primary={shift} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        {/* General Filters */}
        <div className="general-filters">
          {/* Month Dropdown */}
          <FormControl variant="outlined" className="dropdown-group">
            <InputLabel id="month-select-label">Select Month</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(Number(e.target.value))}
              label="Select Month"
            >
              {months.map((month) => (
                <MenuItem key={month.value} value={month.value}>
                  {month.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Week Multi-Select Dropdown with Checkboxes */}
          <FormControl variant="outlined" className="dropdown-group">
            <InputLabel id="weeks-select-label">Select Week(s)</InputLabel>
            <Select
              labelId="weeks-select-label"
              id="weeks-select"
              multiple
              value={selectedWeeks}
              onChange={handleWeeksChange}
              input={<OutlinedInput label="Select Week(s)" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {weeksOptions.map((week) => (
                <MenuItem key={week} value={week}>
                  <Checkbox checked={selectedWeeks.indexOf(week) > -1} />
                  <ListItemText primary={week} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Day Multi-Select Dropdown with Checkboxes and Select All */}
          <FormControl variant="outlined" className="dropdown-group">
            <InputLabel id="days-select-label">Select Day(s)</InputLabel>
            <Select
              labelId="days-select-label"
              id="days-select"
              multiple
              value={selectedDays}
              onChange={handleDaysChange}
              input={<OutlinedInput label="Select Day(s)" />}
              renderValue={(selected) =>
                selected.includes("Select All") ? "All Days" : selected.join(", ")
              }
            >
              <MenuItem value="Select All">
                <Checkbox checked={allDaysSelected} />
                <ListItemText primary="Select All" />
              </MenuItem>
              {daysOptions.map((day) => (
                <MenuItem key={day} value={day}>
                  <Checkbox checked={selectedDays.indexOf(day) > -1} />
                  <ListItemText primary={day} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Clear Filters Button */}
        <div className="dropdown-group">
          <Button
            variant="contained"
            color="secondary"
            className="clear-filters-button"
            onClick={clearAllFilters}
          >
            Clear Filters
          </Button>
        </div>
      </div> {/* End of dropdown-container */}

      {/* Display message or charts/KPIs */}
      {!selectedVehicle1 || !selectedVehicle2 ? (
        <div className="message">
          <p>Please select both vehicles for comparison.</p>
        </div>
      ) : (
        <React.Fragment>
          {/* Chart Section */}
          <div className="chart-section">
            {/* Chart for Vehicle 1 */}
            <div className="chart-container">
              <h3>
                {hostNameMapping[selectedVehicle1] || selectedVehicle1} -{" "}
                {formatShifts(selectedShifts1)}
              </h3>
              {dataForVehicle1 && dataForVehicle1.labels.length > 0 ? (
                <Bar
                  data={{
                    labels: dataForVehicle1.labels,
                    datasets: [
                      {
                        label: "Forward Duration",
                        data: dataForVehicle1.forwardDurations,
                        backgroundColor: durationColors.Forward,
                      },
                      {
                        label: "Reverse Duration",
                        data: dataForVehicle1.reverseDurations,
                        backgroundColor: durationColors.Reverse,
                      },
                      {
                        label: "Idle Duration",
                        data: dataForVehicle1.idleDurations,
                        backgroundColor: durationColors.Idle,
                      },
                    ],
                  }}
                  options={chartOptions}
                  height={400}
                />
              ) : (
                <p>No data available for the selected filters.</p>
              )}
            </div>

            {/* Chart for Vehicle 2 */}
            <div className="chart-container">
              <h3>
                {hostNameMapping[selectedVehicle2] || selectedVehicle2} -{" "}
                {formatShifts(selectedShifts2)}
              </h3>
              {dataForVehicle2 && dataForVehicle2.labels.length > 0 ? (
                <Bar
                  data={{
                    labels: dataForVehicle2.labels,
                    datasets: [
                      {
                        label: "Forward Duration",
                        data: dataForVehicle2.forwardDurations,
                        backgroundColor: durationColors.Forward,
                      },
                      {
                        label: "Reverse Duration",
                        data: dataForVehicle2.reverseDurations,
                        backgroundColor: durationColors.Reverse,
                      },
                      {
                        label: "Idle Duration",
                        data: dataForVehicle2.idleDurations,
                        backgroundColor: durationColors.Idle,
                      },
                    ],
                  }}
                  options={chartOptions}
                  height={400}
                />
              ) : (
                <p>No data available for the selected filters.</p>
              )}
            </div>
          </div>

          {/* KPI Section */}
          <div className="kpi-section">
            {/* KPI for Vehicle 1 */}
            <div className="kpi-card">
              <h4>
                KPI for {hostNameMapping[selectedVehicle1] || selectedVehicle1} -{" "}
                {formatShifts(selectedShifts1)}
              </h4>
              <table>
                <thead>
                  <tr>
                    <th>Duration Type</th>
                    <th>Total</th>
                    <th>Percentage</th>
                    <th>Average / Day</th>
                    <th>Max</th>
                    <th>Min</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Forward</td>
                    <td>{kpiVehicle1.totalForward}</td>
                    <td>{kpiVehicle1.percentageForward}</td>
                    <td>{kpiVehicle1.averageForward}</td>
                    <td>{kpiVehicle1.maxForward}</td>
                    <td>{kpiVehicle1.minForward}</td>
                  </tr>
                  <tr>
                    <td>Reverse</td>
                    <td>{kpiVehicle1.totalReverse}</td>
                    <td>{kpiVehicle1.percentageReverse}</td>
                    <td>{kpiVehicle1.averageReverse}</td>
                    <td>{kpiVehicle1.maxReverse}</td>
                    <td>{kpiVehicle1.minReverse}</td>
                  </tr>
                  <tr>
                    <td>Idle</td>
                    <td>{kpiVehicle1.totalIdle}</td>
                    <td>{kpiVehicle1.percentageIdle}</td>
                    <td>{kpiVehicle1.averageIdle}</td>
                    <td>{kpiVehicle1.maxIdle}</td>
                    <td>{kpiVehicle1.minIdle}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* KPI for Vehicle 2 */}
            <div className="kpi-card">
              <h4>
                KPI for {hostNameMapping[selectedVehicle2] || selectedVehicle2} -{" "}
                {formatShifts(selectedShifts2)}
              </h4>
              <table>
                <thead>
                  <tr>
                    <th>Duration Type</th>
                    <th>Total</th>
                    <th>Percentage</th>
                    <th>Average / Day</th>
                    <th>Max</th>
                    <th>Min</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Forward</td>
                    <td>{kpiVehicle2.totalForward}</td>
                    <td>{kpiVehicle2.percentageForward}</td>
                    <td>{kpiVehicle2.averageForward}</td>
                    <td>{kpiVehicle2.maxForward}</td>
                    <td>{kpiVehicle2.minForward}</td>
                  </tr>
                  <tr>
                    <td>Reverse</td>
                    <td>{kpiVehicle2.totalReverse}</td>
                    <td>{kpiVehicle2.percentageReverse}</td>
                    <td>{kpiVehicle2.averageReverse}</td>
                    <td>{kpiVehicle2.maxReverse}</td>
                    <td>{kpiVehicle2.minReverse}</td>
                  </tr>
                  <tr>
                    <td>Idle</td>
                    <td>{kpiVehicle2.totalIdle}</td>
                    <td>{kpiVehicle2.percentageIdle}</td>
                    <td>{kpiVehicle2.averageIdle}</td>
                    <td>{kpiVehicle2.maxIdle}</td>
                    <td>{kpiVehicle2.minIdle}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default Comparison;
