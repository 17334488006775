import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import "./SystemHealth.css";

// Import MUI components
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";

function SystemHealth() {
  const [deviceCycleData, setDeviceCycleData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedHost, setSelectedHost] = useState("cem003");
  const [selectedDevice, setSelectedDevice] = useState(["Camera Rear"]);
  const [selectedAnomalyFlag, setSelectedAnomalyFlag] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(10); // Default to October
  const [selectedDay, setSelectedDay] = useState([]); // No days selected by default
  const [clickedBarData, setClickedBarData] = useState(null);

  const hostNameMapping = {
    cem001: "cem001",
    cem003: "TOYOTA (serial # CR46744)",
    cem004: "R1700 Scoop (Serial# CS46629)",
    cem005: "cem005",
    cem006: "AD30 Truck (serial # CU9810)",
    cem008: "AD45 Truck (Serial #CS44476)",
  };

  const hosts = ["cem001", "cem003", "cem004", "cem005", "cem006", "cem008"];
  const devices = ["LiDAR Front", "LiDAR Rear", "Camera Front", "Camera Rear"];
  const anomalyFlags = [
    { label: "Normal", value: "Normal" },
    { label: "Anomaly (longer duration)", value: "Anomaly" },
  ];
  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  // Calculate days in the selected month
  const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };
  const currentYear = new Date().getFullYear();
  const days = Array.from(
    { length: daysInMonth(selectedMonth, currentYear) },
    (_, i) => i + 1
  );

  useEffect(() => {
    fetch("/api/SystemHealth")
      .then((response) => response.json())
      .then((data) => {
        const sortedData = data.sort(
          (a, b) => new Date(a.Timestamp) - new Date(b.Timestamp)
        );
        setDeviceCycleData(sortedData);
        setFilteredData(sortedData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    let filtered = deviceCycleData;

    if (selectedHost) {
      filtered = filtered.filter((item) => item.DeviceID === selectedHost);
    }
    if (selectedDevice.length) {
      filtered = filtered.filter((item) =>
        selectedDevice.includes(item.Device)
      );
    }

    if (
      selectedAnomalyFlag.length > 0 &&
      selectedAnomalyFlag.length < anomalyFlags.length
    ) {
      filtered = filtered.filter((item) =>
        selectedAnomalyFlag.includes(
          anomalyFlags.find((flag) => flag.value === item.AnomalyFlag)?.label ||
            item.AnomalyFlag
        )
      );
    }

    if (selectedMonth) {
      filtered = filtered.filter(
        (item) => new Date(item.Timestamp).getMonth() + 1 === selectedMonth
      );
    }

    if (selectedDay.length) {
      filtered = filtered.filter((item) =>
        selectedDay.includes(new Date(item.Timestamp).getDate())
      );
    }

    const sortedFilteredData = filtered.sort(
      (a, b) => new Date(a.Timestamp) - new Date(b.Timestamp)
    );
    setFilteredData(sortedFilteredData);
  }, [
    selectedHost,
    selectedDevice,
    selectedAnomalyFlag,
    selectedMonth,
    selectedDay,
    deviceCycleData,
  ]);

  const toggleSelection = (selection, setter, value) => {
    setter((prevSelection) => {
      if (prevSelection.includes(value)) {
        return prevSelection.filter((item) => item !== value);
      } else {
        return [...prevSelection, value];
      }
    });
  };

  const handleBarClick = (elements) => {
    if (elements.length > 0) {
      const index = elements[0].index;
      const clickedData = filteredData[index];
      setClickedBarData(clickedData);
    }
  };

  const barData = {
    labels: filteredData.map((item) => new Date(item.Timestamp).getDate()),
    datasets: [
      {
        label: "Time",
        data: filteredData.map((item) => item.TimeDifference || 0),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75, 192, 192, 0.8)",
        hoverBorderColor: "rgba(75, 192, 192, 1)",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (_, elements) => handleBarClick(elements),
    plugins: {
      legend: { position: "top" },
      tooltip: { enabled: true },
      datalabels: { display: false },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Day of the Month",
        },
      },
      y: {
        title: {
          display: true,
          text: "Time",
        },
      },
    },
  };

  return (
    <div className="device-cycle-container">
      <h2 className="title">System Health</h2>

      {/* Filters Section */}
      <div className="filters-section">
        {/* Vehicle Dropdown */}
        <div className="filter-group">
          <FormControl variant="outlined" className="filter-form-control">
            <InputLabel id="vehicle-select-label">Vehicle</InputLabel>
            <Select
              labelId="vehicle-select-label"
              id="vehicle-select"
              value={selectedHost}
              onChange={(e) => setSelectedHost(e.target.value)}
              label="Vehicle"
            >
              {hosts.map((host) => (
                <MenuItem key={host} value={host}>
                  {hostNameMapping[host] || host}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Devices Dropdown */}
        <div className="filter-group">
          <FormControl variant="outlined" className="filter-form-control">
            <InputLabel id="devices-select-label">Devices</InputLabel>
            <Select
              labelId="devices-select-label"
              id="devices-select"
              multiple
              value={selectedDevice}
              onChange={(e) => setSelectedDevice(e.target.value)}
              input={<OutlinedInput label="Devices" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {devices.map((device) => (
                <MenuItem key={device} value={device}>
                  <Checkbox checked={selectedDevice.indexOf(device) > -1} />
                  <ListItemText primary={device} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Anomaly Flags Dropdown */}
        <div className="filter-group">
          <FormControl variant="outlined" className="filter-form-control">
            <InputLabel id="anomaly-flags-select-label">Anomaly Flags</InputLabel>
            <Select
              labelId="anomaly-flags-select-label"
              id="anomaly-flags-select"
              multiple
              value={selectedAnomalyFlag}
              onChange={(e) => setSelectedAnomalyFlag(e.target.value)}
              input={<OutlinedInput label="Anomaly Flags" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {anomalyFlags.map((flag) => (
                <MenuItem key={flag.label} value={flag.label}>
                  <Checkbox checked={selectedAnomalyFlag.indexOf(flag.label) > -1} />
                  <ListItemText primary={flag.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Month Dropdown */}
        <div className="filter-group">
          <FormControl variant="outlined" className="filter-form-control">
            <InputLabel id="month-select-label">Month</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={selectedMonth}
              onChange={(e) => {
                setSelectedMonth(e.target.value);
                setSelectedDay([]); // Reset selected days when month changes
              }}
              label="Month"
            >
              {months.map((month) => (
                <MenuItem key={month.value} value={month.value}>
                  {month.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      {/* Days Filter */}
      <div className="days-filter-container">
        <h3>Select Days</h3>
        <div className="days-grid">
          {days.map((day) => {
            const isAnomaly = filteredData.some(
              (data) =>
                new Date(data.Timestamp).getDate() === day &&
                data.AnomalyFlag === "Anomaly"
            );
            const isSelected = selectedDay.includes(day);

            return (
              <div
                key={day}
                className={`day-cell ${
                  isAnomaly ? "anomaly-day" : ""
                } ${isSelected ? "selected-day" : ""}`}
                onClick={() => toggleSelection(selectedDay, setSelectedDay, day)}
              >
                {day}
              </div>
            );
          })}
        </div>
      </div>

      {/* Bar Chart */}
      <div className="chart-container">
        <h3>Bootup Time</h3>
        <Bar data={barData} options={chartOptions} />
      </div>

      {/* Additional Analysis Section */}
      {clickedBarData && (
        <div className="analysis-container">
          <h3>Additional Analysis</h3>
          <p>
            <strong>Device ID:</strong> {clickedBarData.DeviceID}
          </p>
          <p>
            <strong>SubFolderID:</strong> {clickedBarData.SubFolderID}
          </p>
          <p>
            <strong>Log Level:</strong> {clickedBarData.LogLevel}
          </p>
          <p>
            <strong>Device Status:</strong> {clickedBarData.DeviceStatus}
          </p>
          <p>
            <strong>Anomaly Type:</strong> {clickedBarData.AnomalyFlag}
          </p>
          <p>
            <strong>Message:</strong> {clickedBarData.Message}
          </p>
          <p>
            <strong>Bootup Time:</strong>{" "}
            {clickedBarData.TimeDifference || "N/A"}
          </p>
          <p>
            <strong>Initialized At:</strong>{" "}
            {clickedBarData.Device_INITIALIZED || "N/A"}
          </p>
          <p>
            <strong>Offline At:</strong>{" "}
            {clickedBarData.Device_OFFLINE || "N/A"}
          </p>
          <p>
            <strong>Online At:</strong> {clickedBarData.Device_ONLINE || "N/A"}
          </p>
        </div>
      )}
    </div>
  );
}

export default SystemHealth;
