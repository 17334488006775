// src/components/ProtectedRoute.js

import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Alert, Button, Container, Typography, Box } from "@mui/material";

const ProtectedRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  if (!user) {
    return (
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "#ffffff",
            borderRadius: 2,
            boxShadow: 3,
            textAlign: "center",
          }}
        >
          <Typography variant="h5" gutterBottom>
            Unauthorized Access
          </Typography>
          <Alert severity="warning" sx={{ mb: 2 }}>
            It seems like you're trying to access a page without logging in.
          </Alert>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/login", { replace: true })}
          >
            Go to Login Page
          </Button>
        </Box>
      </Container>
    );
  }

  return children;
};

export default ProtectedRoute;
