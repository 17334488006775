// src/AssetUtilization.js

import React, { useState, useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chart.js/auto";
import "./AssetUtilization.css";

// Import MUI components
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Button,
} from "@mui/material";

Chart.register(ChartDataLabels);

function AssetUtilization() {
  // State variables
  const [timeCalculationData, setTimeCalculationData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [processedData, setProcessedData] = useState([]);
  const [selectedBarData, setSelectedBarData] = useState(null);
  const [selectedHost, setSelectedHost] = useState("cem006");
  const [selectedMonth, setSelectedMonth] = useState(11);
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [selectedShift, setSelectedShift] = useState([]);
  const [selectedDominantLog, setSelectedDominantLog] = useState([]);
  const [averageDuration, setAverageDuration] = useState(0);
  const [medianDuration, setMedianDuration] = useState(0);

  // New State for Line Chart
  const [selectedDurationTypes, setSelectedDurationTypes] = useState([
    "Idle",
    "Forward",
    "Reverse",
  ]);

  // Define unique host options with corresponding host IDs
  const hostOptions = [
    { label: "cem001", value: ["cem001"] },
    { label: "TOYOTA", value: ["cem003", "cem009"] }, // Combined Hosts
    { label: "R1700 Scoop (Serial# CS46629)", value: ["cem004"] },
    { label: "cem005", value: ["cem005"] },
    { label: "AD30 Truck (serial # CU9810)", value: ["cem006"] },
    { label: "AD45 Truck (Serial #CS44476)", value: ["cem008"] },
  ];

  // Months for dropdown
  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  // Weeks and shifts for dropdowns
  const weeks = ["Week 1", "Week 2", "Week 3", "Week 4"];
  const shifts = ["Morning", "Evening"];

  // Dominant Log options (derived from data)
  const [dominantLogs, setDominantLogs] = useState([]);

  // Fetch data from backend on component mount
  useEffect(() => {
    fetch("/api/time-calculation")
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched Time Calculation Data:", data); // Debugging log
        if (data.length > 0) {
          setTimeCalculationData(data);
          processData(data);
          extractDominantLogs(data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // Extract unique Dominant Log values for filtering
  const extractDominantLogs = (data) => {
    const logs = Array.from(new Set(data.map((item) => item["Dominant Log"])));
    setDominantLogs(logs);
  };

  // Process raw data to include all necessary fields
  const processData = (data) => {
    const newData = data.map((item) => ({
      UID: item.UID,
      Host: item.Host,
      Date: item.Date,
      MasterStart: item["Master Start"],
      MasterEnd: item["Master End"],
      RosoutStart: item["Rosout Start"],
      RosoutEnd: item["Rosout End"],
      ForwardDuration: item["Forward Duration (mins)"],
      ReverseDuration: item["Reverse Duration (mins)"],
      IdleDuration: item["Idle Duration (mins)"],
      TotalDuration: item["Total Duration (mins)"],
      MasterDuration: item["Master Duration (mins)"],
      RosoutDuration: item["Rosout Duration (mins)"],
      DominantLog: item["Dominant Log"],
      MorningShift: item["Morning Shift (mins)"],
      NightShift: item["Night Shift (mins)"],
    }));
    console.log("Processed Data:", newData); // Debugging log
    setProcessedData(newData);
    filterData(newData);
  };

  // Filter data based on selected filters
  const filterData = (data) => {
    let filtered = data;

    // Filter by Host
    if (selectedHost) {
      const selectedHostOption = hostOptions.find((host) =>
        host.value.includes(selectedHost)
      );
      // Fallback if selectedHost doesn't match any option
      const hostsToFilter = selectedHostOption
        ? selectedHostOption.value
        : [selectedHost];

      filtered = filtered.filter((item) => hostsToFilter.includes(item.Host));
    }

    // Filter by Month
    if (selectedMonth) {
      filtered = filtered.filter((item) => {
        const [month, day, year] = item.Date.split("/");
        return parseInt(month) === selectedMonth;
      });
    }

    // Filter by Weeks
    if (selectedWeeks.length > 0) {
      filtered = filtered.filter((item) => {
        const day = parseInt(item.Date.split("/")[1]);
        const week = Math.ceil(day / 7);
        return selectedWeeks.includes(`Week ${week}`);
      });
    }

    // Filter by Shift
    if (selectedShift.length > 0) {
      filtered = filtered.filter((item) => {
        const [_, time] = item.MasterStart.split(" ");
        const hour = parseInt(time.split(":")[0]);
        const isMorning = hour >= 6 && hour < 18;
        const shift = isMorning ? "Morning" : "Evening";
        return selectedShift.includes(shift);
      });
    }

    // Filter by Dominant Log
    if (selectedDominantLog.length > 0) {
      filtered = filtered.filter((item) =>
        selectedDominantLog.includes(item.DominantLog)
      );
    }

    console.log("Filtered Data:", filtered); // Debugging log
    setFilteredData(filtered);
  };

  // Re-filter data when filters change
  useEffect(() => {
    filterData(processedData);
  }, [
    selectedHost,
    selectedMonth,
    selectedWeeks,
    selectedShift,
    selectedDominantLog,
    processedData,
  ]);

  // Helper function to format duration
  const formatDuration = (minutes) => {
    if (minutes < 60) {
      return `${Math.round(minutes)} minutes`;
    } else {
      const hrs = Math.floor(minutes / 60);
      const mins = Math.round(minutes % 60);
      return `${hrs} hour${hrs > 1 ? "s" : ""} ${mins} minutes`;
    }
  };

  // Helper function to format percentage
  const formatPercentage = (percentage) => {
    return `${percentage.toFixed(1)}%`;
  };

  // Aggregate data by day for bar chart
  const aggregateDataByDay = (data) => {
    const dayMap = {};

    data.forEach((item) => {
      const day = parseInt(item.Date.split("/")[1]);
      if (!dayMap[day]) {
        dayMap[day] = { Idle: 0, Forward: 0, Reverse: 0 };
      }
      dayMap[day].Idle += item.IdleDuration;
      dayMap[day].Forward += item.ForwardDuration;
      dayMap[day].Reverse += item.ReverseDuration;
    });

    const labels = Object.keys(dayMap)
      .map((day) => parseInt(day))
      .sort((a, b) => a - b);
    const idleDurations = labels.map((day) => dayMap[day].Idle);
    const forwardDurations = labels.map((day) => dayMap[day].Forward);
    const reverseDurations = labels.map((day) => dayMap[day].Reverse);

    console.log("Aggregated Results:", {
      labels,
      idleDurations,
      forwardDurations,
      reverseDurations,
    }); // Debugging log

    return {
      labels,
      idleDurations,
      forwardDurations,
      reverseDurations,
    };
  };

  // Calculate statistics (average and median)
  const calculateStatistics = (aggregatedResults) => {
    const durations = [
      ...aggregatedResults.idleDurations,
      ...aggregatedResults.forwardDurations,
      ...aggregatedResults.reverseDurations,
    ];
    const totalDurations = durations.filter((d) => d > 0);
    const average =
      totalDurations.length > 0
        ? totalDurations.reduce((a, b) => a + b, 0) / totalDurations.length
        : 0;

    const sortedDurations = [...totalDurations].sort((a, b) => a - b);
    const mid = Math.floor(sortedDurations.length / 2);
    const median =
      sortedDurations.length % 2 !== 0
        ? sortedDurations[mid]
        : (sortedDurations[mid - 1] + sortedDurations[mid]) / 2;

    setAverageDuration(average);
    setMedianDuration(median);
  };

  // Reset all filters
  const resetFilters = () => {
    setSelectedHost("cem003");
    setSelectedMonth(10);
    setSelectedWeeks([]);
    setSelectedShift([]);
    setSelectedDominantLog([]);
  };

  // Chart data state for bar chart
  const [chartData, setChartData] = useState({
    labels: [],
    idleDurations: [],
    forwardDurations: [],
    reverseDurations: [],
  });

  // Update chart data when filtered data changes
  useEffect(() => {
    const aggregatedResults = aggregateDataByDay(filteredData);
    calculateStatistics(aggregatedResults);
    setChartData(aggregatedResults);
  }, [filteredData]);

  // Combined bar data for chart
  const combinedBarData = {
    labels: chartData.labels,
    datasets: [
      {
        label: "Idle",
        data: chartData.idleDurations,
        backgroundColor: "#FF9800", // Orange
      },
      {
        label: "Forward",
        data: chartData.forwardDurations,
        backgroundColor: "#4CAF50", // Green
      },
      {
        label: "Reverse",
        data: chartData.reverseDurations,
        backgroundColor: "#9E9E9E", // Grey
      },
    ],
  };

  // Line chart data for percentage durations
  const percentageData = {
    labels: chartData.labels,
    datasets: [
      {
        label: "Idle Percentage",
        data: chartData.labels.map((day, index) =>
          (chartData.idleDurations[index] / 1440) * 100
        ),
        borderColor: "#FF9800", // Orange
        backgroundColor: "#FF9800",
        tension: 0.4,
        hidden: !selectedDurationTypes.includes("Idle"),
        fill: false,
      },
      {
        label: "Forward Percentage",
        data: chartData.labels.map((day, index) =>
          (chartData.forwardDurations[index] / 1440) * 100
        ),
        borderColor: "#4CAF50", // Green
        backgroundColor: "#4CAF50",
        tension: 0.4,
        hidden: !selectedDurationTypes.includes("Forward"),
        fill: false,
      },
      {
        label: "Reverse Percentage",
        data: chartData.labels.map((day, index) =>
          (chartData.reverseDurations[index] / 1440) * 100
        ),
        borderColor: "#9E9E9E", // Grey
        backgroundColor: "#9E9E9E",
        tension: 0.4,
        hidden: !selectedDurationTypes.includes("Reverse"),
        fill: false,
      },
    ],
  };

  // Chart options for bar chart with stacking enabled
  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: "Day of the Month",
          padding: { top: 20 },
        },
        ticks: {
          autoSkip: false,
          font: { size: 12 },
          padding: 15,
        },
        grid: {
          display: false,
        },
        stacked: true, // Enable stacking
      },
      y: {
        title: { display: true, text: "Duration (Minutes)" },
        beginAtZero: true,
        ticks: {
          stepSize: 50,
        },
        stacked: true, // Enable stacking
      },
    },
    plugins: {
      datalabels: {
        display: false, // Disables data labels on bars
        color: "black",
        font: {
          weight: "bold",
          size: 10,
        },
        formatter: (value) => (value !== null ? formatDuration(value) : ""),
      },
      tooltip: {
        callbacks: {
          label: (context) =>
            `${context.dataset.label}: ${formatDuration(context.parsed.y)}`,
        },
      },
      legend: {
        position: "top",
        labels: {
          boxWidth: 15,
          boxHeight: 15,
        },
      },
    },
    layout: {
      padding: {
        bottom: 30,
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const datasetIndex = elements[0].datasetIndex;
        const index = elements[0].index;
        const day = chartData.labels[index];
        const type = combinedBarData.datasets[datasetIndex].label;

        // Filter data based on day and type
        const selectedData = filteredData.filter((item) => {
          const dayOfItem = parseInt(item.Date.split("/")[1]);
          return dayOfItem === parseInt(day);
        });

        setSelectedBarData({ day, type, data: selectedData });
      }
    },
  };

  // Chart options for line chart
  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: "Day of the Month",
          padding: { top: 20 },
        },
        ticks: {
          autoSkip: false,
          font: { size: 12 },
          padding: 15,
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        title: { display: true, text: "Percentage (%)" },
        ticks: {
          stepSize: 10,
          callback: function (value) {
            return value + "%";
          },
        },
      },
    },
    plugins: {
      datalabels: {
        display: false, // Disables data labels on lines
      },
      tooltip: {
        callbacks: {
          label: (context) =>
            `${context.dataset.label}: ${formatPercentage(context.parsed.y)}`,
        },
      },
      legend: {
        position: "top",
        labels: {
          boxWidth: 15,
          boxHeight: 15,
        },
      },
    },
  };

  // Handle duration type selection
  const handleDurationTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDurationTypes(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div className="AssetUtilization-container">
      <h2 className="log-time-title">Asset Utilization</h2>

      {/* KPI Cards */}
      <div className="kpi-container">
        <div className="kpi-card">
          <h4>Average Duration Per Day</h4>
          <p>{formatDuration(Math.round(averageDuration))}</p>
        </div>
        <div className="kpi-card">
          <h4>Median Duration Per Day</h4>
          <p>{formatDuration(Math.round(medianDuration))}</p>
        </div>
      </div>

      {/* Filters Section */}
      <div className="filters-section">
        {/* Vehicle Dropdown */}
        <div className="log-time-dropdown-group">
          <FormControl variant="outlined" className="log-time-form-control">
            <InputLabel id="vehicle-select-label">Vehicle</InputLabel>
            <Select
              labelId="vehicle-select-label"
              id="vehicle-select"
              value={selectedHost}
              onChange={(e) => setSelectedHost(e.target.value)}
              label="Vehicle"
            >
              {hostOptions.map((hostOption) => (
                <MenuItem key={hostOption.label} value={hostOption.value[0]}>
                  {hostOption.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Month Dropdown */}
        <div className="log-time-dropdown-group">
          <FormControl variant="outlined" className="log-time-form-control">
            <InputLabel id="month-select-label">Month</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              label="Month"
            >
              {months.map((month) => (
                <MenuItem key={month.value} value={month.value}>
                  {month.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Weeks Multi-Select Dropdown with Checkboxes */}
        <div className="log-time-dropdown-group">
          <FormControl variant="outlined" className="log-time-form-control">
            <InputLabel id="weeks-select-label">Weeks</InputLabel>
            <Select
              labelId="weeks-select-label"
              id="weeks-select"
              multiple
              value={selectedWeeks}
              onChange={(e) => setSelectedWeeks(e.target.value)}
              input={<OutlinedInput label="Weeks" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {weeks.map((week) => (
                <MenuItem key={week} value={week}>
                  <Checkbox checked={selectedWeeks.indexOf(week) > -1} />
                  <ListItemText primary={week} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Shift Multi-Select Dropdown with Checkboxes */}
        <div className="log-time-dropdown-group">
          <FormControl variant="outlined" className="log-time-form-control">
            <InputLabel id="shift-select-label">Shift</InputLabel>
            <Select
              labelId="shift-select-label"
              id="shift-select"
              multiple
              value={selectedShift}
              onChange={(e) => setSelectedShift(e.target.value)}
              input={<OutlinedInput label="Shift" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {shifts.map((shift) => (
                <MenuItem key={shift} value={shift}>
                  <Checkbox checked={selectedShift.indexOf(shift) > -1} />
                  <ListItemText primary={shift} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Dominant Log Multi-Select Dropdown with Checkboxes */}
        <div className="log-time-dropdown-group">
          <FormControl variant="outlined" className="log-time-form-control">
            <InputLabel id="dominant-log-select-label">Dominant Log</InputLabel>
            <Select
              labelId="dominant-log-select-label"
              id="dominant-log-select"
              multiple
              value={selectedDominantLog}
              onChange={(e) => setSelectedDominantLog(e.target.value)}
              input={<OutlinedInput label="Dominant Log" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {dominantLogs.map((log) => (
                <MenuItem key={log} value={log}>
                  <Checkbox checked={selectedDominantLog.indexOf(log) > -1} />
                  <ListItemText primary={log} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Clear Filters Button */}
        <div className="clear-filters-group">
          <Button
            variant="contained"
            color="secondary"
            className="clear-filters-button"
            onClick={resetFilters}
            aria-label="Clear all filters"
          >
            Clear Filters
          </Button>
        </div>
      </div>

      <div className="section-divider"></div>

      {/* Chart Section */}
      <div className="log-time-chart-container">
        <h3>Asset Utilization</h3>
        <Bar data={combinedBarData} options={barChartOptions} />
      </div>

      {/* Duration Type Selection for Line Chart */}
      <div className="line-chart-controls">
        <FormControl variant="outlined" className="line-chart-form-control">
          {/* <InputLabel id="duration-type-select-label">
            Select Duration Types
          </InputLabel> */}
          {/* <Select
            labelId="duration-type-select-label"
            id="duration-type-select"
            multiple
            value={selectedDurationTypes}
            onChange={handleDurationTypeChange}
            input={<OutlinedInput label="Select Duration Types" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {["Idle", "Forward", "Reverse"].map((type) => (
              <MenuItem key={type} value={type}>
                <Checkbox checked={selectedDurationTypes.indexOf(type) > -1} />
                <ListItemText primary={type} />
              </MenuItem>
            ))}
          </Select> */}
        </FormControl>
      </div>

      {/* Additional Line Chart for Percentage Durations */}
      <div className="log-time-line-chart-container">
        <h3>Duration Percentage Over Days</h3>
        <Line data={percentageData} options={lineChartOptions} />
      </div>

      {/* Additional Analysis Section */}
      {selectedBarData && (
        <div className="additional-analysis-container">
          <h3>
            Additional Analysis for Day {selectedBarData.day} -{" "}
            {selectedBarData.type} Duration
          </h3>
          <div className="analysis-section">
            <p>
              <strong>Vehicle:</strong>{" "}
              {hostOptions.find((host) =>
                host.value.includes(selectedHost)
              )?.label || selectedHost}
            </p>
            <p>
              <strong>Date:</strong> {selectedBarData.day}{" "}
              {months.find((m) => m.value === selectedMonth)?.name}{" "}
              {new Date().getFullYear()}
            </p>
            <p>
              <strong>Total {selectedBarData.type} Duration:</strong>{" "}
              {formatDuration(
                selectedBarData.data.reduce((total, item) => {
                  if (selectedBarData.type === "Idle") {
                    return total + item.IdleDuration;
                  } else if (selectedBarData.type === "Forward") {
                    return total + item.ForwardDuration;
                  } else if (selectedBarData.type === "Reverse") {
                    return total + item.ReverseDuration;
                  }
                  return total;
                }, 0)
              )}
            </p>
            <hr />
            {/* Detailed Data Table */}
            <div className="detailed-data-table">
              <table>
                <thead>
                  <tr>
                    <th>UID</th>
                    <th>Host</th>
                    <th>Date</th>
                    <th>Master Start</th>
                    <th>Master End</th>
                    <th>Rosout Start</th>
                    <th>Rosout End</th>
                    <th>Forward Duration (mins)</th>
                    <th>Reverse Duration (mins)</th>
                    <th>Idle Duration (mins)</th>
                    <th>Total Duration (mins)</th>
                    <th>Master Duration (mins)</th>
                    <th>Rosout Duration (mins)</th>
                    <th>Dominant Log</th>
                    <th>Morning Shift (mins)</th>
                    <th>Night Shift (mins)</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedBarData.data.map((item, index) => {
                    // Determine shift type for color coding
                    const [_, time] = item.MasterStart.split(" ");
                    const hour = parseInt(time.split(":")[0]);
                    const isMorningShift = hour >= 6 && hour < 18;
                    const shiftClass = isMorningShift ? "day-shift" : "night-shift";

                    return (
                      <tr key={index} className={shiftClass}>
                        <td>{item.UID}</td>
                        <td>
                          {hostOptions.find((host) =>
                            host.value.includes(item.Host)
                          )?.label || item.Host}
                        </td>
                        <td>{item.Date}</td>
                        <td>{item.MasterStart}</td>
                        <td>{item.MasterEnd}</td>
                        <td>{item.RosoutStart}</td>
                        <td>{item.RosoutEnd}</td>
                        <td>{item.ForwardDuration}</td>
                        <td>{item.ReverseDuration}</td>
                        <td>{item.IdleDuration}</td>
                        <td>{item.TotalDuration}</td>
                        <td>{item.MasterDuration}</td>
                        <td>{item.RosoutDuration}</td>
                        <td>{item.DominantLog}</td>
                        <td>{item.MorningShift}</td>
                        <td>{item.NightShift}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AssetUtilization;
